/* global google */
import { Circle, MarkerType } from '@propertypal/shared/src/types/map';
import { LatLng } from '@propertypal/shared/src/types/marker';
import React, { Component, ReactNode } from 'react';
import config from '../../../../../app.config.json';
import MapContainer from './GoogleMap.style';

export interface InfoWindow {
  lat: number;
  lng: number;
  content: {
    image?: string;
    text?: string;
  };
}

interface Props {
  lat?: number;
  lng?: number;
  zoom?: number;
  width: number | string;
  height: number | string;
  gestureHandling?: string;
  markers?: MarkerType[];
  circles?: Circle[];
  infoWindows?: InfoWindow[];
  polygons?: (object | undefined)[];
  activeMarker?: MarkerType | null;
  hideStreetView?: boolean;
  onMarkerClick?: (marker: any) => void;
  onRegionChange?: () => void;
  children?: ReactNode;
  onLoad?: () => void;
}

export const DEFAULT_ZOOM = 8;
export const DEFAULT_LAT = 54.4;
export const DEFAULT_LNG = -5.9;
export const DEFAULT_COORD = {
  latitude: DEFAULT_LAT,
  longitude: DEFAULT_LNG,
  mapZoomLevel: DEFAULT_ZOOM,
};

// eslint-disable-next-line max-len
export const SCRIPT_SRC = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsWebKey}&callback=initMap&loading=async&v=3`;

const DEFAULT_ICON = {
  default: {
    src: '/map/map-marker.svg',
    width: 28,
    height: 43,
  },
  selected: {
    src: '/map/selected-map-marker.svg',
    width: 28,
    height: 43,
  },
};

let map: google.maps.Map | null = null;

export const getMapInstance = () => map;

// testing use only
export const setMapInstance = (instance: any) => {
  map = instance;
};

export const getZoom = () => {
  return map?.getZoom() || DEFAULT_ZOOM;
};

export const getCenter = () => {
  if (map) {
    const center = map.getCenter();

    if (center) {
      return { lat: center.lat(), lng: center.lng() };
    }
  }

  return { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
};

export const getLatLngFromPoint = (x: number, y: number) => {
  if (map) {
    const bounds = map.getBounds();
    const projection = map.getProjection();
    const zoom = map.getZoom();

    if (bounds && projection && zoom) {
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
      const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
      const scale = 2 ** zoom;

      if (topRight && bottomLeft) {
        const worldPoint = new google.maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y);
        const latLng = projection.fromPointToLatLng(worldPoint);

        if (latLng) {
          return { lat: latLng.lat(), lng: latLng.lng() };
        }
      }
    }
  }

  return null;
};

export const centerToPolygon = (polygons: number[][][][], padding: number | google.maps.Padding) => {
  if (map) {
    const bounds = new google.maps.LatLngBounds();

    polygons.forEach((polygon) => {
      polygon[0].forEach((coord) => {
        bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
      });
    });

    map.fitBounds(bounds, padding);
  }
};

export const centerToBounds = (latLngs: LatLng[], padding: number) => {
  if (map) {
    const bounds = new google.maps.LatLngBounds();

    latLngs.forEach((latLng) => {
      bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });

    map.fitBounds(bounds, padding);
  }
};

export const centerToLatLng = (latLng: LatLng) => {
  if (map) {
    map.setCenter(new google.maps.LatLng(latLng.lat, latLng.lng));
  }
};

export const getBounds = () => {
  if (map) {
    const bounds = map.getBounds();

    if (bounds) {
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      return {
        north: northEast.lat(),
        east: northEast.lng(),
        south: southWest.lat(),
        west: southWest.lng(),
      };
    }
  }

  return undefined;
};

const initMapInstance = (
  gestureHandling?: string,
  lat?: number,
  lng?: number,
  zoom?: number,
  hideStreetView?: boolean,
) => {
  const mapElement = document.getElementById('map');
  const mapContainer = document.getElementById('map_container');

  if ((!mapElement && mapContainer) || (!map && mapElement && mapContainer)) {
    // handles instances where fast refresh clears map instance, but not the element
    if (!map && mapElement) {
      mapElement.remove();
    }

    const newMap = document.createElement('div');
    newMap.id = 'map';

    mapContainer.appendChild(newMap);

    map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: { lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG },
      zoom: zoom || DEFAULT_ZOOM,
      scrollwheel: gestureHandling === 'greedy' ? true : null,
      gestureHandling,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: !hideStreetView,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      mapId: config.googleMapsId,
    });
    map.getStreetView().setMotionTracking(false);
  } else if (map && mapElement && mapContainer) {
    map.setOptions({
      center: { lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG },
      zoom: zoom || DEFAULT_ZOOM,
      gestureHandling,
      streetViewControl: !hideStreetView,
    });

    mapElement.style.display = 'block';
    mapContainer.appendChild(mapElement);
  }
};

const removeMapInstance = () => {
  const mapElement = document.getElementById('map');

  if (map) {
    map.getStreetView().setVisible(false);
    map.setMapTypeId('roadmap');
  }

  if (mapElement) {
    mapElement.style.display = 'none';
    document.body.appendChild(mapElement);
  }
};

const createMapInstance = (
  gestureHandling: string | undefined,
  lat: number | undefined,
  lng: number | undefined,
  zoom: number | undefined,
  hideStreetView: boolean | undefined,
  callback: () => void,
) => {
  const script = document.querySelector<HTMLScriptElement>(`script[src="${SCRIPT_SRC}"]`);

  if (!script) {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = SCRIPT_SRC;
    googleMapScript.async = true;
    googleMapScript.onload = () => {
      googleMapScript.setAttribute('data-loaded', 'true');
    };

    // @ts-ignore
    window.initMap = async () => {
      initMapInstance(gestureHandling, lat, lng, zoom, hideStreetView);
      await callback();
    };

    document.head.appendChild(googleMapScript);
  } else if (script && script.hasAttribute('data-loaded') && typeof google !== 'undefined') {
    initMapInstance(gestureHandling, lat, lng, zoom, hideStreetView);
    callback();
  }
};

class GoogleMap extends Component<Props> {
  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  infoWindows: google.maps.marker.AdvancedMarkerElement[] = [];

  polygons: google.maps.Data.Feature[][] = [];

  circles: google.maps.Circle[] = [];

  listener?: google.maps.MapsEventListener;

  componentDidMount() {
    createMapInstance(
      this.props.gestureHandling,
      this.props.lat,
      this.props.lng,
      this.props.zoom,
      this.props.hideStreetView,
      async () => {
        if (map) {
          await google.maps.importLibrary('marker');

          this.listener = google.maps.event.addListener(map, 'idle', () => {
            if (this.props.onRegionChange) {
              this.props.onRegionChange();
            }
          });

          map.data.setStyle((feature) => {
            return {
              fillColor: feature.getProperty('fillColor') as string,
              fillOpacity: feature.getProperty('fillOpacity') as number,
              strokeColor: feature.getProperty('strokeColor') as string,
              strokeWeight: feature.getProperty('strokeWeight') as number,
            };
          });
        }

        this.updateMarkers();
        this.updatePolygons();
        this.updateCircles();
        this.updateInfoWindows();
        if (this.props.onLoad) this.props.onLoad();
      },
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.markers) !== JSON.stringify(this.props.markers) ||
      prevProps.activeMarker?.id !== this.props.activeMarker?.id
    ) {
      this.updateMarkers();
    }

    if (JSON.stringify(prevProps.polygons) !== JSON.stringify(this.props.polygons)) {
      this.updatePolygons();
    }

    if (JSON.stringify(prevProps.circles) !== JSON.stringify(this.props.circles)) {
      this.updateCircles();
    }

    if (JSON.stringify(prevProps.infoWindows) !== JSON.stringify(this.props.infoWindows)) {
      this.updateInfoWindows();
    }
  }

  componentWillUnmount() {
    if (this.listener) {
      google.maps.event.removeListener(this.listener);
    }

    this.clearCircles();
    this.clearMarkers();
    this.clearPolygons();
    this.clearInfoWindows();

    removeMapInstance();
  }

  clearInfoWindows() {
    this.infoWindows.forEach((infoWindow) => {
      infoWindow.map = null;
    });

    this.infoWindows = [];
  }

  async updateInfoWindows() {
    this.clearInfoWindows();

    if (map && this.props.infoWindows) {
      this.props.infoWindows.forEach((item) => {
        const content = document.createElement('div');
        content.className = 'advanced-marker';

        if (item.content.image) {
          const img = document.createElement('img');
          img.src = item.content.image;
          content.appendChild(img);
        } else if (item.content.text) {
          const text = document.createElement('p');
          text.textContent = item.content.text;
          content.appendChild(text);
        }

        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: new google.maps.LatLng(item.lat, item.lng),
          map,
          content,
        });

        this.infoWindows.push(marker);
      });
    }
  }

  clearPolygons() {
    if (map && this.polygons.length) {
      this.polygons.forEach((polygon) => {
        polygon.forEach((feature) => {
          if (map) map.data.remove(feature);
        });
      });

      this.polygons = [];
    }
  }

  updatePolygons() {
    this.clearPolygons();

    if (this.props.polygons) {
      this.props.polygons.forEach((polygon) => {
        if (map && polygon) this.polygons.push(map.data.addGeoJson(polygon));
      });
    }
  }

  clearCircles() {
    this.circles.forEach((circle) => {
      circle.setMap(null);
    });
  }

  updateCircles() {
    this.clearCircles();

    if (map && this.props.circles) {
      this.props.circles.forEach((circle) => {
        const newCircle = new google.maps.Circle({
          map,
          radius: circle.radius,
          center: new google.maps.LatLng(circle.latitude, circle.longitude),
          strokeColor: 'rgba(238, 73, 0)',
          strokeWeight: 3,
          fillColor: 'rgba(238, 73, 0)',
          fillOpacity: 0.2,
        });

        this.circles.push(newCircle);
      });
    }
  }

  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.map = null;
    });
  }

  updateMarkers() {
    if (map && !this.props.markers) {
      this.clearMarkers();
    }

    if (map && this.props.markers) {
      const prevMarkers: google.maps.marker.AdvancedMarkerElement[] = [...this.markers];
      const nextMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

      this.props.markers.forEach((marker) => {
        const { activeMarker } = this.props;

        // Find if marker is already rendered on map
        const index = prevMarkers.findIndex((prevMarker) => {
          if (prevMarker.position) {
            // @ts-ignore
            const wasActive = prevMarker.content.className.includes('pp-map-marker-active');
            const isActive =
              !!activeMarker &&
              prevMarker.position.lat === activeMarker.lat &&
              prevMarker.position.lng === activeMarker.lng;

            return (
              prevMarker.position.lat === marker.lat && prevMarker.position.lng === marker.lng && isActive === wasActive
            );
          }

          return false;
        });

        if (index >= 0) {
          // Keep marker rendered
          const slice = prevMarkers.splice(index, 1)[0];
          nextMarkers.push(slice);
        } else {
          // Render new map marker
          const isActive = !!activeMarker && marker.lat === activeMarker.lat && marker.lng === activeMarker.lng;
          const content = document.createElement('div');
          const img = document.createElement('img');
          const label = document.createElement('p');

          content.className = `pp-map-marker ${isActive ? 'pp-map-marker-active' : ''}`;
          label.innerHTML = !marker.units ? '' : marker.units && marker.units < 100 ? marker.units.toString() : '+';
          label.className = marker.units && marker.units < 100 ? '' : 'pp-marker-plus';
          const icon = marker.icon || DEFAULT_ICON;
          img.src = isActive ? icon.selected.src : icon.default.src;
          content.append(img);
          content.append(label);

          const mMarker = new google.maps.marker.AdvancedMarkerElement({
            content,
            position: marker,
            map,
          });

          mMarker.addListener('click', () => {
            if (this.props.onMarkerClick) {
              this.props.onMarkerClick(marker);
            }
          });

          nextMarkers.push(mMarker);
        }
      });

      // Clear any old markers
      prevMarkers.forEach((marker) => {
        marker.map = null;
      });

      this.markers = nextMarkers;
    }
  }

  render() {
    return (
      <MapContainer id="map_container" style={{ width: this.props.width, height: this.props.height }}>
        {this.props.children}
      </MapContainer>
    );
  }
}

export default GoogleMap;
